export enum LessonType {
  Article = "article-lesson",
  Video = "video-lesson",
  Audio = "audio-lesson",
  Quiz = "quiz-lesson",
}

export type EngagementStatus = "started" | "completed" | null;

export enum ContentType {
  Image = "image",
  Text = "text",
  CtaSection = "cta-section",
}

export enum TextStyle {
  H1 = "h1",
  Body = "body",
  H2 = "h2",
}

export interface ContentItem {
  type: ContentType;
  src?: string | null;
  thumbnail?: string | null;
  medium?: string | null;
  large?: string | null;
  textStyle?: TextStyle | null;
  text?: string | null;
  cta?: string | null;
  isDownloadable: boolean;
  url?: string | null;
  variation?: string | null;
  contents: ContentItem[];
}

export type ImageSizes = {
  medium: string;
  large: string;
  thumbnail: string;
  source: string;
};

export type LessonBase = {
  id: number;
  coverImage: ImageSizes;
  duration: number;
  title: string;
  engagementStatus: EngagementStatus;
  introduction: {
    heading: string;
    description: string;
  };
};

export type LessonArticle = LessonBase & {
  type: LessonType.Article;
  contents: ContentItem[];
};

export type LessonVideo = LessonBase & {
  type: LessonType.Video;
  content: {
    description: string;
    provider: "YouTube";
    title: string;
    url: string;
  };
};

export type LessonAudio = LessonBase & {
  type: LessonType.Audio;
  content: {
    description: string;
    provider: "Spotify";
    title: string;
    url: string;
  };
};
export type LessonQuiz = LessonBase & {
  type: LessonType.Quiz;
  content: {
    description: string;
    title: string;
    url: string;
  };
};

export type Lesson = LessonArticle | LessonVideo | LessonAudio | LessonQuiz;

export type LessonInfo = {
  id: number;
  coverImage: ImageSizes;
  duration: number;
  title: string;
  type: LessonType;
  engagementStatus: EngagementStatus | null;
  description: string;
};

export type Course = {
  id: number;
  title: string;
  learningGoals: string[];
  description: string;
  lessons: LessonInfo[];
  coverImage: ImageSizes;
  duration: number;
  themes: string[];
};
