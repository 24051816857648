import { useState } from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WizardContainer from "@src/views/onboarding/components/WizardContainer";
import WizardActions from "@src/views/onboarding/components/WizardActions";
import { ReactComponent as ChildStar } from "@src/resources/images/boy_child_star.svg";
import BoldLink from "@src/components/BoldLink";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles(({ spacing, breakpoints, customShadows }) => ({
  title: {
    marginBottom: spacing(4),
  },
  body: {
    margin: spacing(3, 0),
    opacity: 0.7,
    fontWeight: 400,
    lineHeight: "32px",
    [breakpoints.up("sm")]: {
      margin: spacing(3, 4),
    },
  },
  checkboxBox: {
    margin: spacing(3, 0),
    display: "flex",
    flexDirection: "row",
    [breakpoints.up("sm")]: {
      margin: spacing(3, 4),
    },
  },
  checkboxContainer: {
    display: "flex",
  },
  checkbox: {
    position: "relative",
    top: 0,
    height: spacing(3),
  },
  checkboxText: {
    opacity: 1,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  button: {
    marginTop: spacing(1),
    width: "100%",
    color: "white",
  },
  box: {
    width: 360,
    margin: "auto",
    marginTop: spacing(3),
  },
  imageContainer: {
    textAlign: "right",
    maxWidth: 600,
    margin: "0 auto",
    [breakpoints.down("sm")]: {
      paddingBottom: 110,
    },
  },
  cardActions: {
    justifyContent: "center",
    borderTop: "solid 2px rgba(21, 35, 97, .05);",
    width: "100%",
    [breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: spacing(1),
      boxShadow: customShadows.cardActionBackgroundShadow,
      display: "block",
    },
  },
}));

const ExplicitConsent = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [acceptedMedicalDataProcessing, setAcceptedMedicalDataProcessing] =
    useState(false);
  const [acceptedSignUpToNewsLetter, setSignUpToNewsLetter] = useState(false);
  const [clickedContinueButton, setClickedContinueButton] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setClickedContinueButton(true);
    moveToNextStep(
      acceptedTermsAndConditions,
      acceptedMedicalDataProcessing,
      acceptedSignUpToNewsLetter,
    );
  };

  return (
    <>
      <WizardContainer
        title={t("ExplicitConsent.Title")}
        isTitleCentered={false}
      >
        <form onSubmit={onSubmit}>
          <Typography className={classes.body} variant="subtitle1">
            {t("ExplicitConsent.Body.Part1")}
            <BoldLink isTargetBlank href={t("ExplicitConsent.Body.Link")}>
              {t("ExplicitConsent.Body.Part2")}
            </BoldLink>
            {t("ExplicitConsent.Body.Part3")}
          </Typography>
          <Box className={classes.checkboxBox} component="label">
            <Box className={classes.checkboxContainer}>
              <Checkbox
                data-cy="medicalDataProcessing"
                className={classes.checkbox}
                onClick={() =>
                  setAcceptedMedicalDataProcessing(
                    !acceptedMedicalDataProcessing,
                  )
                }
              />
            </Box>
            <Typography variant="body2" className={classes.checkboxText}>
              {t("ExplicitConsent.MedicalDataProcessing")}
            </Typography>
          </Box>
          <Box className={classes.checkboxBox} component="label">
            <Box className={classes.checkboxContainer}>
              <Checkbox
                data-cy="termsAndConditions"
                className={classes.checkbox}
                onClick={() =>
                  setAcceptedTermsAndConditions(!acceptedTermsAndConditions)
                }
              />
            </Box>
            <Typography variant="body2" className={classes.checkboxText}>
              {t("ExplicitConsent.TermsAndConditions.Part1")}
              <BoldLink
                isTargetBlank
                href={t("ExplicitConsent.TermsAndConditions.Link")}
              >
                {t("ExplicitConsent.TermsAndConditions.Part2")}
              </BoldLink>
              {t("ExplicitConsent.TermsAndConditions.Part3")}
            </Typography>
          </Box>
          <Box className={classes.checkboxBox} component="label">
            <Box className={classes.checkboxContainer}>
              <Checkbox
                data-cy="signUpToNewsLetter"
                className={classes.checkbox}
                onClick={() =>
                  setSignUpToNewsLetter(!acceptedSignUpToNewsLetter)
                }
              />
            </Box>
            <Typography variant="body2" className={classes.checkboxText}>
              {t("ExplicitConsent.SignUpToNewsLetter")}
            </Typography>
          </Box>
          <WizardActions
            disabled={
              !acceptedTermsAndConditions ||
              !acceptedMedicalDataProcessing ||
              clickedContinueButton
            }
            nextStepButtonLabel={t("ExplicitConsent.ButtonText")}
          />
        </form>
      </WizardContainer>
      <Box className={classes.imageContainer}>
        <ChildStar className={classes.image} />
      </Box>
    </>
  );
};

export default ExplicitConsent;
