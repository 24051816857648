import ContentCard, {
  ContentCardProps,
} from "@openup/shared/components/Cards/ContentCard/ContentCard";
import { ContentItem } from "@src/queries/contents";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface ArticleCardProps {
  article: ContentItem;
  variant?: ContentCardProps["variant"];
  isThemeVisible?: boolean;
  isBackgroundEnabled?: boolean;
  onMouseUp?: MouseEventHandler<HTMLAnchorElement>;
}

const ArticleCard = ({
  article: {
    title,
    url,
    thumbnailPath,
    readTime,
    publishedDate,
    themes: contentTopic,
  },
  variant = "standard",
  isThemeVisible = true,
  isBackgroundEnabled = true,
  onMouseUp,
}: ArticleCardProps) => {
  const { t } = useTranslation();
  const firstTopic = contentTopic?.[0];
  const topicWithUpperLetter =
    firstTopic?.length > 0
      ? firstTopic.charAt(0).toUpperCase() + firstTopic.slice(1)
      : null;

  return (
    <ContentCard
      key={`${publishedDate}-${url}`}
      title={title}
      href={url}
      imageSrc={thumbnailPath}
      minsToRead={
        readTime ? t("SharedStrings.EstimatedMinutes", { time: readTime }) : ""
      }
      onMouseUp={onMouseUp}
      topic={
        isThemeVisible && topicWithUpperLetter
          ? t(`SharedStrings.${topicWithUpperLetter}`)
          : undefined
      }
      isLoading={false}
      target="_blank"
      variant={variant}
      isBackgroundEnabled={isBackgroundEnabled}
    />
  );
};

const getLoadingCards = (amount) =>
  Array.from({ length: amount }, (_, index) => (
    <ContentCard
      key={index}
      title={undefined}
      href={undefined}
      imageSrc={undefined}
      isLoading
    />
  ));

export default ArticleCard;
export { getLoadingCards };
