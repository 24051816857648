import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "@src/resources/styles/phone-number-input.css";
import authService from "@src/services/auth.service";
import { LANGUAGE_TYPES_PHONE_PICKER } from "@src/utils/constants";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import {
  LanguageCodeDutchNl,
  LanguageCodeEnglishGb,
  LanguageCodeGermanDe,
  LanguageCodeFrenchFr,
  LanguageCodeSpanishEs,
} from "@src/utils/languageHelpers";

const useStyles = makeStyles((theme) => ({
  headerMessage: {
    color: theme.colors.primaryDarkBlueWithTransparency,
    width: "240px",
    height: "16px",
    opacity: 0.7,
  },
  errorMessage: {
    color: theme.colors.errorMain,
    marginTop: theme.spacing(0.5),
  },
  fullWidth: { width: "100% !important" },
}));

const PhoneNumberComponent = ({
  isError,
  isTouched,
  setFieldTouched,
  setFieldValue,
  phoneNumberValue,
  disabled = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslatedNavigate();
  const [languageUse, setLanguageUse] = useState(
    LANGUAGE_TYPES_PHONE_PICKER.NL,
  );
  const { i18n } = useTranslation();
  const currentUser = authService.getUserFromStorage();

  const inputStyle = {
    width: "100%",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderColor: "#5b6590",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    opacity: disabled ? 0.6 : 1,
  };
  const containerStyle = {
    borderWidth: 0,
    width: "100%",
  };
  const searchStyle = {
    fontFamily: "Montserrat",
    padding: "5px 0px 5px 10px",
    width: "90%",
  };
  const dropdownStyle = { maxHeight: "300px" };

  useEffect(() => {
    switch (i18n.language) {
      case LanguageCodeEnglishGb:
        setLanguageUse(LANGUAGE_TYPES_PHONE_PICKER.EN);
        break;
      case LanguageCodeDutchNl:
        setLanguageUse(LANGUAGE_TYPES_PHONE_PICKER.NL);
        break;
      case LanguageCodeGermanDe:
        setLanguageUse(LANGUAGE_TYPES_PHONE_PICKER.DE);
        break;
      case LanguageCodeFrenchFr:
        setLanguageUse(LANGUAGE_TYPES_PHONE_PICKER.FR);
        break;
      case LanguageCodeSpanishEs:
        setLanguageUse(LANGUAGE_TYPES_PHONE_PICKER.ES);
        break;
      default:
        setLanguageUse(LANGUAGE_TYPES_PHONE_PICKER.NL);
        break;
    }
  }, [i18n.language]);

  const handlePhoneNumberChange = (value, country, e, formattedValue) => {
    setFieldValue("phoneNumber", formattedValue);
  };
  return (
    <Box>
      <Typography variant="caption" className={classes.headerMessage}>
        {t("Booking.CompleteBooking.Phone")}
      </Typography>
      <PhoneInput
        onBlur={(_) => {
          setFieldTouched("phoneNumber", true);
        }}
        disabled={disabled}
        country={
          currentUser?.phoneNumber
            ? ""
            : languageUse ?? LANGUAGE_TYPES_PHONE_PICKER.NL
        }
        value={phoneNumberValue}
        specialLabel=""
        containerStyle={containerStyle}
        searchStyle={searchStyle}
        dropdownStyle={dropdownStyle}
        inputStyle={inputStyle}
        autocompleteSearch
        enableLongNumbers
        priority={{
          [LANGUAGE_TYPES_PHONE_PICKER.NL]: 0,
          [LANGUAGE_TYPES_PHONE_PICKER.BE]: 1,
          [LANGUAGE_TYPES_PHONE_PICKER.FR]: 2,
          [LANGUAGE_TYPES_PHONE_PICKER.DE]: 3,
          [LANGUAGE_TYPES_PHONE_PICKER.ES]: 4,
          [LANGUAGE_TYPES_PHONE_PICKER.GB]: 5,
        }}
        enableSearch={false}
        preferredCountries={[
          LANGUAGE_TYPES_PHONE_PICKER.NL,
          LANGUAGE_TYPES_PHONE_PICKER.BE,
          LANGUAGE_TYPES_PHONE_PICKER.FR,
          LANGUAGE_TYPES_PHONE_PICKER.DE,
          LANGUAGE_TYPES_PHONE_PICKER.ES,
          LANGUAGE_TYPES_PHONE_PICKER.GB,
        ]}
        onChange={handlePhoneNumberChange}
        inputProps={{
          required: true,
          "data-cy": "phone-number",
        }}
      />
      {isError && isTouched ? (
        <Typography variant="caption" className={classes.errorMessage}>
          {t("Validations.InvalidPhoneNumber")}
        </Typography>
      ) : null}
    </Box>
  );
};

export default PhoneNumberComponent;
