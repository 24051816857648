import { useMutation, useQuery } from "react-query";
import axios from "axios";
import authService from "@src/services/auth.service";
import { apiPaths } from "@src/utils/constants";

export const useMutationUser = (callback) =>
  useMutation((user) => axios.put(apiPaths.updateUser, user), {
    onSettled: (response) => {
      if (response) {
        callback?.();
      }
    },
  });

export const useUserProfile = () =>
  useQuery([apiPaths.profile], async ({ queryKey: [url] }) => {
    const { data } = await axios.get(url);
    return data;
  });

export const useUpdateUserPhoneNumber = (token) =>
  useMutation(
    (newPhoneNumber) =>
      axios.put(
        token
          ? `${apiPaths.updateUserPhoneNumberWithToken}?token=${token}`
          : `${apiPaths.updateUserPhoneNumber}`,
        {
          number: newPhoneNumber,
        },
      ),
    {
      onSettled: (_response) => {},
      onError: (error) => error,
    },
  );

export const useGuestUserProfile = (email, token) =>
  useQuery([apiPaths.guestProfile], async () => {
    try {
      const response = await axios.get(
        `${apiPaths.guestProfile}?token=${token}&email=${encodeURIComponent(
          email,
        )}`,
      );
      return response?.data;
    } catch (error) {
      if (error.response.status === 404) {
        return null;
      }
      throw error;
    }
  });

export const useNonConfirmedClientIsRegisteredEmail = (email, token) =>
  useQuery([apiPaths.nonConfirmedClientIsRegisteredEmail, email], async () => {
    try {
      const response = axios.get(
        `${apiPaths.nonConfirmedClientIsRegisteredEmail}/${email}/${token}`,
      );
      return response;
    } catch (error) {
      if (error.response.status === 401) {
        return undefined;
      }
      return null;
    }
  });

export const changePassword = async (oldPassword, newPassword) =>
  axios.post(`${apiPaths.changeUserPassword}`, {
    oldPassword,
    newPassword,
  });

export const updateLanguage = (languageCode, logout = true) =>
  axios.put(`${apiPaths.updateLanguage}?logout=${logout}`, { languageCode });

export const updateUserLanguageCode = (languageCode, logout = true) => {
  const currentUser = authService.getUserFromStorage();

  if (currentUser.languageCode !== languageCode) {
    return updateLanguage(languageCode, logout);
  }

  return undefined;
};

export const useCreateGuestUser = () =>
  useMutation(
    async ({
      email,
      firstName,
      lastName,
      yearOfBirth,
      employerId,
      phoneNumber,
      acceptedTermsAndConditions,
      acceptedMedicalDataProcessing,
      isPartner,
      token,
      signUpToNewsletter,
    }) =>
      axios.post(`${apiPaths.registerGuestUser}?token=${token}`, {
        email,
        firstName,
        lastName,
        yearOfBirth,
        employerId,
        phoneNumber,
        acceptedTermsAndConditions,
        acceptedMedicalDataProcessing,
        isPartner,
        signUpToNewsletter,
      }),
    {
      onSettled: (response) => {
        if (response) {
          return response.data;
        }
        return undefined;
      },
    },
  );
