import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { SESSION_NOTES_SECTIONS } from "@src/utils/constants";

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2.5, 3, 5, 3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      width: "110%",
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  body: {
    marginBottom: theme.spacing(3),
  },
}));

const SessionNotesPaper = ({ consult }) => {
  const classes = useStyle();
  const { t } = useTranslatedNavigate();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Typography variant="h6" className={classes.title}>
        {t("MySessionsView.Notes.Notes.Title", {
          psychologistFullname: consult.employeeFullName,
        })}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {t("MySessionsView.Notes.Notes.Background")}
      </Typography>
      <Typography
        variant="body2"
        className={classes.body}
        dangerouslySetInnerHTML={{
          __html: consult.dossierNotes?.find(
            (note) => note.question === SESSION_NOTES_SECTIONS.REASON_BOOK,
          )?.answer,
        }}
      />
      <Typography variant="subtitle1" className={classes.subtitle}>
        {t("MySessionsView.Notes.Notes.Discussed")}
      </Typography>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: consult.dossierNotes?.find(
            (note) =>
              note.question === SESSION_NOTES_SECTIONS.SESSION_DISCUSSED,
          )?.answer,
        }}
      />
    </Paper>
  );
};

export default SessionNotesPaper;
