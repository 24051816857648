import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Page from "@src/components/Page";
import MenuList from "@src/components/OpenUpMenuList";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import BackgroundImage from "@src/resources/backgrounds/ellipse-bg.svg";
import {
  updateEngagementStatus,
  useQueryCourse,
  useQueryLesson,
} from "@src/queries/courses";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LessonIntro from "@openup/shared/components/LessonIntro/LessonIntro";
import Spinner from "@openup/shared/components/Spinner/Spinner";
import NothingFound from "@openup/shared/components/NothingFound/NothingFound";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from "react";
import LessonArticle from "@openup/shared/components/LessonArticle/LessonArticle";
import CourseProgress from "@openup/shared/components/CourseProgress/CourseProgress";
import LessonCard, {
  LessonCardProps,
} from "@openup/shared/components/Cards/LessonCard/LessonCard";
import { getLessonIcon, getLessonTypeKey } from "@src/views/courses/CourseView";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import Button from "@openup/shared/components/Button/Button";
import {
  convertAPIContent,
  getLessonIndex,
} from "@src/utils/CourseLessonViewUtils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Lesson, LessonType } from "@src/models/Course";
import useIsDesktop from "@openup/shared/hooks/useIsDesktop";
import LessonVideo from "@src/views/courses/LessonVideo";
import LessonAudio from "@src/views/courses/LessonAudio";
import CourseHeaderCard from "@openup/shared/components/Cards/CourseHeaderCard/CourseHeaderCard";
import CourseMenu from "@openup/shared/components/CourseMenu/CourseMenu";
import { useMutation } from "react-query";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    background: `url(${BackgroundImage}) no-repeat`,
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    [theme.breakpoints.down("md")]: {
      lineHeight: "48px",
      fontSize: 35,
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "64px",
    },
  },
  menuGrid: {
    backgroundColor: theme.colors.trueWhite,
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-3.75),
    marginRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3.75),
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
  },
  welcomeText: {
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0.5px",
    opacity: 0.7,
    color: theme.colors.primaryDarkBlue,
    marginBottom: theme.spacing(6),
  },
  boxWelcomeText: {
    margin: `${theme.spacing(0)} auto`,
  },
}));

// Timeout for media playback to be considered done
const mediaMinPlaybackTime = 30000;

interface LessonMainContentProps {
  lesson: Lesson;
  onNextStep: () => void;
}

const LessonMainContent: FC<LessonMainContentProps> = ({
  lesson,
  onNextStep,
}) => {
  const [isMediaPlaybackDone, setIsMediaPlaybackDone] = useState(false);
  const mediaPlaybackTimeout = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();

  switch (lesson.type) {
    case LessonType.Article:
      return (
        <LessonArticle
          contents={lesson.contents.map(convertAPIContent)}
          onNextClick={onNextStep}
          className="p-4 sm:p-0"
        />
      );
    case LessonType.Video:
      return (
        <>
          <LessonVideo
            mediaMinPlaybackTime={mediaMinPlaybackTime}
            onMediaPlaybackDone={() => {
              setIsMediaPlaybackDone(true);
            }}
            videoUrl={lesson.content.url}
          />
          <div className="px-8 sm:px-0 py-4 sm:py-0 flex justify-end mt-10 bg-white sm:bg-transparent border-[0] border-t border-solid border-gray-200 sm:border-t-0 absolute bottom-0 left-0 right-0 sm:static">
            <Button
              onClick={() => {
                if (mediaPlaybackTimeout.current) {
                  clearTimeout(mediaPlaybackTimeout.current);
                }
                onNextStep();
                setIsMediaPlaybackDone(false);
              }}
              disabled={!isMediaPlaybackDone}
              className="w-full sm:w-auto"
            >
              {t("CourseProgress.NextLesson")}
              <ArrowForwardIcon className="hidden sm:inline-block" />
            </Button>
          </div>
        </>
      );
    case LessonType.Audio:
      return (
        <>
          <LessonAudio
            mediaMinPlaybackTime={mediaMinPlaybackTime}
            title={lesson.content.title}
            onMediaPlaybackDone={() => {
              setIsMediaPlaybackDone(true);
            }}
            url={lesson.content.url}
          />
          <div className="px-8 sm:px-0 py-4 sm:py-0 flex justify-end mt-10 bg-white sm:bg-transparent border-[0] border-t border-solid border-gray-200 sm:border-t-0 absolute bottom-0 left-0 right-0 sm:static">
            <Button
              onClick={() => {
                if (mediaPlaybackTimeout.current) {
                  clearTimeout(mediaPlaybackTimeout.current);
                }
                onNextStep();
                setIsMediaPlaybackDone(false);
              }}
              disabled={!isMediaPlaybackDone}
              className="w-full sm:w-auto"
            >
              {t("CourseProgress.NextLesson")}
              <ArrowForwardIcon className="hidden sm:inline-block" />
            </Button>
          </div>
        </>
      );
    default:
      return (
        <div>
          Lesson type not supported{" "}
          <Button onClick={onNextStep}>{t("CourseProgress.NextLesson")}</Button>
        </div>
      );
  }
};

const CourseLessonViewContent = ({ courseId, lessonId }) => {
  const classes = useStyles();
  const {
    data: course,
    isLoading: isCourseLoading,
    isError: isCourseError,
    refetch: refetchCourse,
  } = useQueryCourse(parseInt(courseId, 10));
  const {
    data: lesson,
    isLoading: isLessonLoading,
    isError: isLessonError,
  } = useQueryLesson(parseInt(lessonId, 10));
  const { mutate: updateLessonStatus } = useMutation(updateEngagementStatus);
  const { t } = useTranslation();
  const { pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const [lessonStep, setLessonStep] = useState(0);
  const [, setSearchParams] = useSearchParams();
  const isDesktop = useIsDesktop();
  const [isCourseOverviewOpen, setIsCourseOverviewOpen] = useState(false);
  const [activeLessonIndex, setActiveLessonIndex] = useState(0);

  const getLessonStatus = (
    status: Lesson["engagementStatus"] | null,
    index: number,
    length: number,
  ): LessonCardProps["status"] => {
    if (status === "started") {
      if (activeLessonIndex !== index) {
        setActiveLessonIndex(index);
      }
      return "active";
    }

    if (status === null && activeLessonIndex === index) {
      return undefined;
    }

    if (status === "completed") {
      if (activeLessonIndex === index && activeLessonIndex !== length - 1) {
        setActiveLessonIndex(index + 1);
      }

      return "completed";
    }

    if (status === null && activeLessonIndex < index) {
      return "locked";
    }

    return undefined;
  };

  const onNextStep = () => {
    setIsCourseOverviewOpen(false);
    setLessonStep((step) => step + 1);
    refetchCourse();
  };

  useEffect(() => {
    if (lessonStep === 2) {
      setSearchParams({ completed: "1" });
    } else {
      setSearchParams({});
    }
  }, [lessonStep, setSearchParams]);

  if (
    isCourseLoading ||
    isLessonLoading ||
    !lesson ||
    !course ||
    isCourseError ||
    isLessonError
  ) {
    return (
      <div className="h-[calc(100vh-160px)] flex items-center justify-center">
        {!lesson || !course || isCourseError || isLessonError ? (
          <NothingFound text={t("CourseLesson.NotFound")} />
        ) : (
          <Spinner />
        )}
      </div>
    );
  }

  const lessonIndex = getLessonIndex(lesson.id, course) || 0;
  const nextLessonInfo = course.lessons[lessonIndex + 1];

  const onNextLesson = () => {
    if (!nextLessonInfo) {
      navigate(pathT("route.account"));
      return;
    }

    setLessonStep(0);
    navigate(`${pathT("route.course")}/${courseId}/${nextLessonInfo.id}`);
  };

  return (
    <Page title={lesson.title} key={lesson.id}>
      <Container
        maxWidth="lg"
        className={`
          ${lessonStep === 1 ? "!bg-white" : ""}
          ${classes.container}
          ${isDesktop ? "" : "!p-0 !m-0"}
        `}
      >
        <Grid container>
          <Hidden only={["xs", "sm"]}>
            <Grid item xs className={classes.menuGrid}>
              <MenuList menuItemStyle={{ color: "#ffffff" }} isOpen />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            className="relative min-h-[calc(100vh-300px)] sm:min-h-[calc(100vh-64px)]"
          >
            {!isCourseOverviewOpen && (
              <CourseHeaderCard
                className="mb-6 sm:mb-12 sm:rounded-2xl"
                title={course.title}
                imageSrc={lesson.coverImage?.thumbnail}
                isLoading={isCourseLoading || isLessonLoading}
                number={lessonIndex + 1}
                total={course.lessons?.length || 1}
                onOverviewClick={() => {
                  setIsCourseOverviewOpen((isOpen) => !isOpen);
                }}
              />
            )}

            {isCourseOverviewOpen && (
              <CourseMenu
                cards={course?.lessons?.map(
                  (
                    { title, coverImage, engagementStatus, type, duration, id },
                    index,
                  ) => (
                    <LessonCard
                      key={id}
                      className={`w-full ${lessonIndex === index ? "bg-blue-500/5 !cursor-default" : ""}`}
                      title={title}
                      imageSrc={coverImage.thumbnail}
                      status={getLessonStatus(
                        engagementStatus,
                        index,
                        course.lessons.length,
                      )}
                      contentType={t(`SharedStrings.${getLessonTypeKey(type)}`)}
                      href={`${pathT("route.course")}/${courseId}/${id}`}
                      contentTypeIconName={getLessonIcon(type)}
                      estimatedTime={
                        course?.duration
                          ? t("SharedStrings.EstimatedMinutes", {
                              time: duration,
                            })
                          : ""
                      }
                    />
                  ),
                )}
                isLoading={isCourseLoading || isLessonLoading}
                onCloseClick={() => {
                  setIsCourseOverviewOpen(false);
                }}
                onExitClick={() => {
                  navigate(pathT("route.account"));
                }}
                className="sm:relative sm:rounded-2xl sm:shadow sm:mb-12"
                classNameList="bg-white"
              />
            )}

            <div className={isCourseOverviewOpen ? "hidden sm:block" : "block"}>
              {lessonStep === 0 && (
                <LessonIntro
                  className="sm:min-h-0 min-h-[calc(100vh-420px)] p-0 pb-24 sm:p-0"
                  classNameButtonContainer="bg-white sm:bg-transparent shadow-sm sm:shadow-none border-t-gray-200 border-t border-t-solid sm:border-t-0 absolute bottom-0 left-0 right-0 sm:static"
                  imageSrc={
                    lesson.coverImage?.large || lesson.coverImage.source
                  }
                  title={lesson.introduction?.heading}
                  description={lesson.introduction?.description}
                  number={lessonIndex + 1}
                  onStartClick={async () => {
                    await updateLessonStatus({
                      lessonId: lesson.id,
                      status: "started",
                    });
                    onNextStep();
                  }}
                />
              )}

              {lessonStep === 1 && (
                <div className="min-h-[calc(100vh-420px)] sm:min-h-[calc(100vh-324px)] relative">
                  <LessonMainContent
                    lesson={lesson}
                    onNextStep={async () => {
                      await updateLessonStatus({
                        lessonId: lesson.id,
                        status: "completed",
                      });
                      onNextStep();
                    }}
                  />
                </div>
              )}

              {lessonStep === 2 && (
                <CourseProgress
                  nextCard={
                    nextLessonInfo ? (
                      <LessonCard
                        className="w-full bg-white p-4 !border-solid !border-slate-200 !border !rounded-2xl shadow sm:shadow-none"
                        title={nextLessonInfo.title}
                        imageSrc={
                          nextLessonInfo.coverImage?.large ||
                          nextLessonInfo.coverImage.source
                        }
                        status="inactive"
                        contentType={t(
                          `SharedStrings.${getLessonTypeKey(nextLessonInfo.type as Lesson["type"])}`,
                        )}
                        contentTypeIconName={getLessonIcon(
                          nextLessonInfo.type as Lesson["type"],
                        )}
                        estimatedTime={
                          course?.duration
                            ? t("SharedStrings.EstimatedMinutes", {
                                time: nextLessonInfo.duration,
                              })
                            : ""
                        }
                        onClick={onNextLesson}
                      />
                    ) : null
                  }
                  percentage={
                    course.lessons?.length
                      ? ((lessonIndex + 1) / course.lessons.length) * 100
                      : 0
                  }
                  className="sm:relative sm:-left-4 min-h-[calc(100vh-424px)] p-4 sm:min-h-0"
                  onNextClick={onNextLesson}
                  isLoading={false}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const CourseLessonView = () => {
  const { courseId, lessonId } = useParams();

  if (!courseId || !lessonId) {
    return null;
  }

  return (
    <CourseLessonViewContent
      courseId={parseInt(courseId, 10)}
      lessonId={parseInt(lessonId, 10)}
    />
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(withSuspense(CourseLessonView));
