import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation, useOutletContext } from "react-router-dom";

import useTranslatedNavigate from "@src/services/useTranslateNavigate";

import { useIsEmployerSso } from "@src/queries/employers";
import { useGetUnconfirmedSpace } from "@src/queries/spaces";
import { useGuestUserProfile, useCreateGuestUser } from "@src/queries/user";

import { withSnackbar } from "@src/components/SnackBarComponent";
import { timeSnackbar } from "@src/utils/constants";
import CenteredLoader from "@src/components/CenteredLoader";
import sessionStorageService from "@src/services/sessionStorage.service";

import SingleSignOnEmailView from "@src/views/auth/SingleSignOnView/SingleSignOnEmailView";
import CompleteBookingPersonalInfo from "@src/views/booking/introductory/components/CompleteBookingPersonalInfo";
import CompleteBookingEmployerInfo from "@src/views/booking/introductory/components/CompleteBookingEmployerInfo";
import SpacesConfirmationPage from "@src/views/booking/spaces/SpacesConfirmationPage";
import ExplicitConsent from "@src/components/ExplicitConsent";

const STEPS = {
  LOADER: "Loader",
  SSO: "sso",
  COMPLETE_BOOKING_1: "User Information Form Step 1",
  COMPLETE_BOOKING_2: "User Information Form Step 2",
  EXPLICIT_CONSENT: "Consent",
  CONFIRMATION: "Confirmation Screen",
};

/**
 * Component that is shown when a user has clicked the magic link to confirm their space registration
 */
const ConfirmSpaceFlow = ({ ...props }) => {
  const { step, nextStep, setPreventGoingBack, setupFlowSteps, goToStep } =
    useOutletContext();
  const { t } = useTranslatedNavigate();

  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);

  const token = urlSearch.get("spaceToken");
  const email = urlSearch.get("email").replace(/ /g, "+");

  const firstName = useRef("");
  const lastName = useRef("");
  const yearOfBirth = useRef("");
  const phoneNumber = useRef("");
  const employerId = useRef();
  const isPartner = useRef(false);
  const acceptedTermsAndConditions = useRef(false);
  const acceptedMedicalDataProcessing = useRef(false);
  const signUpToNewsletter = useRef(false);

  const onExpiredData = () => {
    props.snackbarShowMessage(t("Validations.TokenExpired"), "error");
    setTimeout(() => {
      window.location.replace(`${t("route.website.spaces")}`);
    }, timeSnackbar);
  };

  const onCouldNotRegister = () => {
    props.snackbarShowMessage(t("spaces.errors.error"), "error");
  };

  const onCouldNotConfirmSpace = () => {
    props.snackbarShowMessage(t("spaces.errors.error"), "error");
  };

  const onExternalError = () => {
    props.snackbarShowMessage(t("spaces.errors.error"), "error");
    setTimeout(() => {
      window.location.replace(`${t("route.website.spaces")}`);
    }, timeSnackbar);
  };

  // 1. Check if the unconfirmed space is still reserved/active. If not, show an error and redirect to spaces page on the website
  // 2. Check if the use account already exists. If yes, proceed to confirmation.
  // 3. If the user does not exist, check if this user email supports SSO. If yes, proceed to login with SSO, and create the external user. Then confirm the space.
  // 4. If the user does not exist, and does not support SSO, proceed to user details form, and create the user. Then confirm the space.

  const { data: guestUserProfile } = useGuestUserProfile(email, token);
  const { data: bookedSpaceDetails, refetch: confirmSpace } =
    useGetUnconfirmedSpace({
      email,
      token,
      onExpiredData,
      onExternalError,
    });
  const { mutateAsync: createGuestUser } = useCreateGuestUser();
  const {
    data: { ssoSupported },
  } = useIsEmployerSso({ email });

  useEffect(() => {
    if (!token || !email) {
      window.location.replace(`${t("route.website.spaces")}`);
    }
    (async () => {
      await setupFlowSteps(STEPS);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  useEffect(() => {
    if (step === STEPS.CONFIRMATION) {
      setPreventGoingBack(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step]);

  const navigateToCorrectStep = () => {
    if (guestUserProfile) {
      if (!guestUserProfile?.isRegistered) {
        goToStep(STEPS.COMPLETE_BOOKING_1);
      } else {
        confirmSpace();
      }
    } else {
      goToStep(STEPS.COMPLETE_BOOKING_1);
    }
  };

  useEffect(() => {
    if (ssoSupported) {
      goToStep(STEPS.SSO);
    } else {
      navigateToCorrectStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [ssoSupported]);

  useEffect(() => {
    if (bookedSpaceDetails) {
      goToStep(STEPS.CONFIRMATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [bookedSpaceDetails]);

  const onSubmitCreateGuestUser = async () => {
    try {
      await createGuestUser({
        firstName: firstName.current,
        lastName: lastName.current,
        email,
        yearOfBirth: yearOfBirth.current,
        employerId: employerId.current,
        phoneNumber: phoneNumber.current,
        acceptedTermsAndConditions: acceptedTermsAndConditions.current,
        acceptedMedicalDataProcessing: acceptedMedicalDataProcessing.current,
        isPartner: isPartner.current,
        token,
        signUpToNewsletter: signUpToNewsletter.current,
      });
      try {
        await confirmSpace();
        goToStep(STEPS.CONFIRMATION);
      } catch {
        onCouldNotConfirmSpace();
      }
    } catch {
      onCouldNotRegister();
    }
  };

  const selectStep = (selectedStep) => {
    switch (selectedStep) {
      case STEPS.SSO:
        return (
          <SingleSignOnEmailView
            hideUI
            onSuccessfulSso={() => {
              navigateToCorrectStep();
            }}
          />
        );
      case STEPS.LOADER:
        return <CenteredLoader />;
      case STEPS.COMPLETE_BOOKING_1:
        return (
          <CompleteBookingPersonalInfo
            moveToNextStep={(values) => {
              firstName.current = values.firstName;
              sessionStorageService.saveNameToStorage(values.firstName);
              lastName.current = values.lastName;
              yearOfBirth.current = values.yearOfBirth;
              phoneNumber.current = values.phoneNumber;
              nextStep();
            }}
          />
        );
      case STEPS.COMPLETE_BOOKING_2:
        return (
          <CompleteBookingEmployerInfo
            moveToNextStep={nextStep}
            employerId={employerId}
            isPartner={isPartner}
          />
        );
      case STEPS.EXPLICIT_CONSENT:
        return (
          <ExplicitConsent
            moveToNextStep={(
              termsAndConditions,
              medicalDataProcessing,
              signUpToNewsLetterValue,
            ) => {
              acceptedTermsAndConditions.current = termsAndConditions;
              acceptedMedicalDataProcessing.current = medicalDataProcessing;
              signUpToNewsletter.current = signUpToNewsLetterValue;
              onSubmitCreateGuestUser();
            }}
          />
        );
      case STEPS.CONFIRMATION:
        return <SpacesConfirmationPage space={bookedSpaceDetails} />;
      default:
        return <CenteredLoader />;
    }
  };

  return <>{selectStep(step)}</>;
};

ConfirmSpaceFlow.propTypes = {
  sampleProp: PropTypes.bool,
};

export default withSnackbar(ConfirmSpaceFlow);
