import React, { useRef } from "react";
import YouTube from "react-youtube";
import getYouTubeId from "get-youtube-id";

interface LessonVideoProps {
  videoUrl: string;
  mediaMinPlaybackTime: number;
  onMediaPlaybackDone: () => void;
}

const LessonVideo: React.FC<LessonVideoProps> = ({
  videoUrl,
  mediaMinPlaybackTime,
  onMediaPlaybackDone,
}) => {
  const mediaPlaybackTimeout = useRef<NodeJS.Timeout | null>(null);

  return (
    <YouTube
      videoId={getYouTubeId(videoUrl) || undefined}
      iframeClassName="rounded-lg overflow-hidden w-full h-auto aspect-video"
      onPlay={() => {
        if (mediaPlaybackTimeout.current) {
          clearTimeout(mediaPlaybackTimeout.current);
        }
        mediaPlaybackTimeout.current = setTimeout(
          onMediaPlaybackDone,
          mediaMinPlaybackTime,
        );
      }}
      onError={() => {
        if (mediaPlaybackTimeout.current) {
          clearTimeout(mediaPlaybackTimeout.current);
        }
        onMediaPlaybackDone();
      }}
      className="p-4 pb-24 sm:p-0"
    />
  );
};

export default LessonVideo;
