import { useInfiniteQuery, useMutation } from "react-query";
import axios from "axios";
import { apiPaths } from "@src/utils/constants";
import { ResponseWithPagination } from "@src/models/Pagination";
import { ContentItem } from "@src/models/Course";

export enum ContentEventType {
  Click = "Click",
}

export interface ContentEvent {
  contentId: number;
  eventType: ContentEventType;
}

interface UseGetContentsProps {
  language;
  type: string;
  theme: string | null;
  page?: number;
  limit?: number;
}

export const useGetContents = ({
  language,
  type,
  theme,
  limit = 16,
}: UseGetContentsProps) => {
  try {
    return useInfiniteQuery(
      [apiPaths.contents, language, type, theme, limit],
      async ({ pageParam = 0 }) => {
        try {
          const response = await axios.post(
            `${apiPaths.contents}?limit=${limit}&page=${pageParam}`,
            {
              language,
              type,
              theme,
            },
          );
          return response.data as ResponseWithPagination<ContentItem[]>;
        } catch (error) {
          return Promise.reject(error);
        }
      },
      {
        getNextPageParam: ({ pagination }) => {
          const nextPage = pagination.hasNextPage
            ? pagination.currentPage + 1
            : undefined;
          return nextPage;
        },
      },
    );
  } catch (error) {
    if (error instanceof Promise) {
      return {
        data: undefined,
        isLoading: true,
        isError: false,
        fetchNextPage: () => {},
        hasNextPage: false,
      };
    }

    return {
      data: null,
      isLoading: false,
      isError: true,
      fetchNextPage: () => {},
      hasNextPage: false,
    };
  }
};

export const useSaveContentEvent = () =>
  useMutation(async (contentEvent: ContentEvent) => {
    await axios.post(`${apiPaths.contents}/event`, contentEvent);
  });
