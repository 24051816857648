import { useQuery } from "react-query";
import axios from "axios";

import { apiPaths } from "@src/utils/constants";
import { useAuthentication } from "@src/services/auth.service";
import { Course, EngagementStatus, Lesson } from "@src/models/Course";

export const useQueryCourse = (courseId: number) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [apiPaths.courses, courseId],
    async () => {
      const { data } = await axios.get<Course>(
        `${apiPaths.courses}/${courseId}`,
      );
      return data;
    },
    {
      enabled: isLoggedIn,
    },
  );
};

export const useQueryLesson = (lessonId: number) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [apiPaths.courses, lessonId],
    async () => {
      const { data } = await axios.get<Lesson>(
        `${apiPaths.courseLesson}/${lessonId}`,
      );
      return data;
    },
    {
      enabled: isLoggedIn,
    },
  );
};

export const updateEngagementStatus = async ({
  lessonId,
  status,
}: {
  lessonId: number;
  status: Exclude<EngagementStatus, null>;
}) => {
  const response = await axios.post<string>(
    `${apiPaths.contents}/${lessonId}/engagement-status`,
    { status },
  );

  if (response.status !== 200) {
    throw new Error("Failed to update engagement status");
  }

  return response.data;
};
