import { getSpotifyEmbedUrl } from "@src/utils/getSpotifyEmbedUrl";
import React, { useEffect, useRef } from "react";

interface LessonAudioProps {
  title: string;
  url: string;
  onMediaPlaybackDone: () => void;
  mediaMinPlaybackTime: number;
}

const LessonAudio: React.FC<LessonAudioProps> = ({
  title,
  url,
  onMediaPlaybackDone,
  mediaMinPlaybackTime,
}) => {
  const mediaPlaybackTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (mediaPlaybackTimeout.current) {
        clearTimeout(mediaPlaybackTimeout.current);
      }
    };
  }, []);

  return (
    <iframe
      title={title}
      className="rounded-lg overflow-hidden p-4 sm:p-0"
      src={getSpotifyEmbedUrl(url) || undefined}
      width="100%"
      height="352"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
      onLoad={() => {
        if (mediaPlaybackTimeout.current) {
          clearTimeout(mediaPlaybackTimeout.current);
        }
        mediaPlaybackTimeout.current = setTimeout(() => {
          onMediaPlaybackDone();
        }, mediaMinPlaybackTime);
      }}
      onError={() => {
        if (mediaPlaybackTimeout.current) {
          clearTimeout(mediaPlaybackTimeout.current);
        }
        onMediaPlaybackDone();
      }}
    />
  );
};

export default LessonAudio;
